import React from "react";
import styles from "../../styles/Benefits.module.css";

import img1 from "../../assets/images/imgg1.png";
import img2 from "../../assets/images/imgg2.png";
import img3 from "../../assets/images/imgg3.png";
import img4 from "../../assets/images/imgg4.png";
import img5 from "../../assets/images/imgg5.png";
import {Grid} from "@mui/material";
import useFetch from "../../customHooks/useFetch";

const Benefits = () => {
  const {data, error, loading} = useFetch(
    `${process.env.REACT_APP_API_BASE_URL}/website/data`,
    {
      page_code: "benefits_kikundi",
      section_code: "page",
    },
    true
  );

  return (
    <div className={styles.main}>
      <Grid container spacing={2} className={styles.container}>
        <Grid
          className={styles.gridItem}
          // item
          xl={6}
          lg={6}
          md={6}
          sm={12}
          xs={12}
          sx={{}}
          order={{xs: 2, sm: 2, md: 1, lg: 1, xl: 1}}
          // spacing={2}
          container
          direction="column"
          alignItems="center"
          alignContent="center"
          justifyContent="center"
        >
          <div className={styles.content}>
            <h1>{data?.content?.card_title1}</h1>
            <h2>{data?.content?.card_subtitle1}</h2>
          </div>
        </Grid>
        <Grid
          className={styles.gridItem}
          // item
          xl={6}
          lg={6}
          md={6}
          sm={12}
          xs={12}
          sx={{}}
          order={{xs: 1, sm: 1, md: 2, lg: 2, xl: 2}}
          // spacing={2}
          container
          direction="column"
          alignItems="center"
          alignContent="center"
          // justifyContent="center"
        >
          <div className={styles.media}>
            <img
              src={`${process.env.REACT_APP_API_BASE_URL_ATTACHMENTS}/contentImages/${data?.images?.image_1}`}
              width={"100%"}
              height={"100%"}
            />
          </div>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={2}
        className={styles.container}
        style={{
          background: "#F8E7E0 ",
        }}
      >
        <Grid
          className={styles.gridItem}
          // item
          xl={6}
          lg={6}
          md={6}
          sm={12}
          xs={12}
          sx={{}}
          order={{xs: 1, sm: 1, md: 1, lg: 1, xl: 1}}
          // spacing={2}
          container
          direction="column"
          alignItems="center"
          alignContent="center"
          justifyContent="center"
        >
          <div className={styles.media}>
            <img
              src={`${process.env.REACT_APP_API_BASE_URL_ATTACHMENTS}/contentImages/${data?.images?.image_2}`}
              width={"100%"}
              height={"100%"}
            />
          </div>
        </Grid>
        <Grid
          className={styles.gridItem}
          // item
          xl={6}
          lg={6}
          md={6}
          sm={12}
          xs={12}
          sx={{}}
          order={{xs: 2, sm: 2, md: 2, lg: 2, xl: 2}}
          // spacing={2}
          container
          direction="column"
          alignItems="center"
          alignContent="center"
          justifyContent="center"
        >
          <div className={styles.subcontent}>
            <h1 style={{color: "#2C335F"}}>{data?.content?.card_title2}</h1>
            <h2 style={{color: "#000"}}>{data?.content?.card_subtitle2}</h2>
          </div>
        </Grid>
      </Grid>

      <Grid container spacing={2} className={styles.container} style={{background: "#E6AB51"}}>
        <Grid
          className={styles.gridItem}
          // item
          xl={6}
          lg={6}
          md={6}
          sm={12}
          xs={12}
          sx={{}}
          order={{xs: 2, sm: 2, md: 1, lg: 1, xl: 1}}
          // spacing={2}
          container
          direction="column"
          alignItems="center"
          alignContent="center"
          justifyContent="center"
        >
          <div className={styles.subcontent}>
            <h1 style={{color: "#3A8558"}}>{data?.content?.card_title3}</h1>
            <h2>{data?.content?.card_subtitle3}</h2>
          </div>
        </Grid>
        <Grid
          className={styles.gridItem}
          // item
          xl={6}
          lg={6}
          md={6}
          sm={12}
          xs={12}
          sx={{}}
          order={{xs: 1, sm: 1, md: 2, lg: 2, xl: 2}}
          // spacing={2}
          container
          direction="column"
          alignItems="center"
          alignContent="center"
          // justifyContent="center"
        >
          <div className={styles.media}>
            <img
              src={`${process.env.REACT_APP_API_BASE_URL_ATTACHMENTS}/contentImages/${data?.images?.image_3}`}
              width={"100%"}
              height={"100%"}
            />
          </div>
        </Grid>
      </Grid>

      <Grid container spacing={2} className={styles.container} style={{background: "#F7E7E0"}}>
        <Grid
          className={styles.gridItem}
          // item
          xl={6}
          lg={6}
          md={6}
          sm={12}
          xs={12}
          sx={{}}
          order={{xs: 1, sm: 1, md: 1, lg: 1, xl: 1}}
          // spacing={2}
          container
          direction="column"
          alignItems="center"
          alignContent="center"
          justifyContent="center"
        >
          <div className={styles.media}>
            <img
              src={`${process.env.REACT_APP_API_BASE_URL_ATTACHMENTS}/contentImages/${data?.images?.image_4}`}
              width={"100%"}
              height={"100%"}
            />
          </div>
        </Grid>
        <Grid
          className={styles.gridItem}
          // item
          xl={6}
          lg={6}
          md={6}
          sm={12}
          xs={12}
          sx={{}}
          order={{xs: 2, sm: 2, md: 2, lg: 2, xl: 2}}
          // spacing={2}
          container
          direction="column"
          alignItems="center"
          alignContent="center"
          justifyContent="center"
        >
          <div className={styles.subcontent}>
            <h1>{data?.content?.card_title4}</h1>
            <h2>{data?.content?.card_subtitle4}</h2>
          </div>
        </Grid>
      </Grid>

      <Grid container spacing={2} className={styles.container} style={{background: "#2E335C"}}>
        <Grid
          className={styles.gridItem}
          // item
          xl={6}
          lg={6}
          md={6}
          sm={12}
          xs={12}
          sx={{}}
          order={{xs: 2, sm: 2, md: 1, lg: 1, xl: 1}}
          // spacing={2}
          container
          direction="column"
          alignItems="center"
          alignContent="center"
          justifyContent="center"
        >
          <div className={styles.subcontent}>
            <h1 style={{color: "#E6AB51"}}>{data?.content?.card_title5}</h1>
            <h2 style={{color: "#FFF"}}>{data?.content?.card_subtitle5}</h2>
          </div>
        </Grid>
        <Grid
          className={styles.gridItem}
          // item
          xl={6}
          lg={6}
          md={6}
          sm={12}
          xs={12}
          sx={{}}
          order={{xs: 1, sm: 1, md: 2, lg: 2, xl: 2}}
          // spacing={2}
          container
          direction="column"
          alignItems="center"
          alignContent="center"
          // justifyContent="center"
        >
          <div className={styles.media}>
            <img
              src={`${process.env.REACT_APP_API_BASE_URL_ATTACHMENTS}/contentImages/${data?.images?.image_5}`}
              width={"100%"}
              height={"100%"}
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default Benefits;
