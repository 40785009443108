import React from "react";

import styles from "../../styles/Home.module.css";
import {Container} from "@mui/material";
import img from "../../assets/images/partners.png";
import img1 from "../../assets/images/img1.png";
import Slider from "../../components/slider";
import useFetch from "../../customHooks/useFetch";
const Partners = () => {
  const {data, error, loading} = useFetch(`${process.env.REACT_APP_API_BASE_URL}/website/companies`, {});

  const title = "Companies that work with us";
  const images = [
    {id: 0, src: img1},
    {id: 1, src: img1},
    {id: 2, src: img1},
    {id: 3, src: img1},
    {id: 4, src: img1},
    {id: 5, src: img1},
    {id: 6, src: img1},
    {id: 7, src: img1},
  ];
  return (
    <div className={styles.homePartnersSection}>
      <h1>{title}</h1>
      {/* <img src={img} width={"100%"} /> */}
      <div style={{marginTop: 50}}>
        <Slider
          data={data?.map((company) => ({
            id: company.id,
            src: `${process.env.REACT_APP_API_BASE_URL_ATTACHMENTS}/companies/${company.image}`,
          }))}
        />
      </div>
    </div>
  );
};

export default Partners;
