import React from "react";
import styles from "../../styles/Why.module.css";
import {Grid} from "@mui/material";
const Benefits = (data) => {
  return (
    <div className={styles.benefits}>
      <h1>{data?.content?.title}</h1>
      <h2>{data?.content?.subtitle}</h2>

      <Grid container spacing={2} className={styles.cards}>
        <Grid
          item
          xl={6}
          lg={6}
          md={6}
          sm={12}
          xs={12}
          sx={{}}
          // spacing={2}
          // container
          // direction="column"
          // alignItems="center"
          // alignContent="center"
          // justifyContent="center"
          className={styles.card}
        >
          <div style={{}}>
            <h1>{data?.content?.card_title6}</h1>
            <h2>{data?.content?.card_subtitle6}</h2>
          </div>
        </Grid>
        <Grid
          item
          xl={6}
          lg={6}
          md={6}
          sm={12}
          xs={12}
          sx={{}}
          // spacing={2}
          // container
          // direction="column"
          // alignItems="center"
          // alignContent="center"
          // justifyContent="center"
          className={styles.card}
        >
          <div style={{}}>
            <h1>{data?.content?.card_title7}</h1>
            <h2>{data?.content?.card_subtitle7}</h2>
          </div>
        </Grid>
        <Grid
          item
          xl={6}
          lg={6}
          md={6}
          sm={12}
          xs={12}
          sx={{}}
          // spacing={2}
          // container
          // direction="column"
          // alignItems="center"
          // alignContent="center"
          // justifyContent="center"
          className={styles.card}
        >
          <div style={{}}>
            <h1>{data?.content?.card_title8}</h1>
            <h2>{data?.content?.card_subtitle8}</h2>
          </div>
        </Grid>
        <Grid
          item
          xl={6}
          lg={6}
          md={6}
          sm={12}
          xs={12}
          sx={{}}
          // spacing={2}
          // container
          // direction="column"
          // alignItems="center"
          // alignContent="center"
          // justifyContent="center"
          className={styles.card}
        >
          <div style={{}}>
            <h1>{data?.content?.card_title9}</h1>
            <h2>{data?.content?.card_subtitle9}</h2>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default Benefits;
