import axios from "axios";
import {useEffect, useState} from "react";

export default function useFetch(url, params = {}, fetchingContent = false) {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    axios
      .get(url, {
        params: params,
      })
      .then((res) => {
        if (fetchingContent) {
          const {content, images} = res.data;
          let contentData = {};
          let imagesData = {};

          if (content) {
            contentData = JSON.parse(content);
          }

          if (images.length > 0) {
            images.map((image) => (imagesData = {...imagesData, [image.code]: image.name}));
          }

          setData({content: contentData, images: imagesData});
        } else {
          setData(res.data);
        }
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [url]);
  return {data, error, loading};
}
