import React from "react";

import styles from "../../styles/Home.module.css";
import {Container} from "@mui/material";
import useFetch from "../../customHooks/useFetch";

const Hero = () => {
  const {data, error, loading} = useFetch(
    `${process.env.REACT_APP_API_BASE_URL}/website/data`,
    {
      page_code: "home",
      section_code: "hero",
    },
    true
  );

  return (
    <Container fixed>
      <div className={styles.homeHeroSection}>
        <h1>{data?.content?.title}</h1>
        <h2>{data?.content?.subtitle}</h2>

        <div>
          <button>DOWNLOAD APP</button>
        </div>
      </div>
    </Container>
  );
};

export default Hero;
