import React from "react";
import {Box, Button, Container, Grid, Typography, useMediaQuery} from "@mui/material";
import {Link} from "react-router-dom";
import Logo from "../../assets/logo/logoo.png";
import styles from "../../styles/Layout.module.css";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import axios from "axios";
import {useState} from "react";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Footer = () => {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(false);
  const [email, setEmail] = useState("");

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
    setError(false);
  };

  const submit = () => {
    if (!email) return;
    setLoading(true);
    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/user/subscribe`, {email})
      .then((res) => {
        setOpen(true);
        setEmail("");
      })
      .catch((error) => {
        if (error.response.data == 1062) {
          setError(true);
          setEmail("");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const mobile = useMediaQuery("(max-width:600px)");

  const navItems = [
    {id: 1, name: "Home", route: "/"},
    {id: 2, name: "About us", route: "/about"},
    {id: 3, name: "Contact Us", route: "/contact"},
  ];

  const footerItems = [
    {id: 1, name: "Services", route: "/#services"},
    {
      id: 2,
      name: "Creative Design",
      route: "/services/marketing-consultancy",
    },
    {
      id: 3,
      name: "Business Development",
      route: "/services/business-development",
    },
    {
      id: 4,
      name: "Marketing Consultancy",
      route: "/services/marketing-consultancy",
    },
    {
      id: 5,
      name: "Mobile App & Web Development",
      route: "/services/mobile-application-and-website-development",
    },
  ];

  return (
    <Grid
      container
      style={{
        paddingBlock: "40px",
        background: "#018754",
      }}
      sx={{
        padding: {
          xs: "20px",
          sm: "40px",
          md: "60px",
          lg: "80px",
          xl: "100px",
        },
      }}
    >
      <Grid
        item
        xl={12}
        lg={12}
        md={12}
        sm={12}
        xs={12}
        sx={{
          display: "flex",
          flexDirection: "column",
          marginBottom: "50px",
        }}
      >
        <div className={styles.footerTitle}>
          <h1>Switch to Kikundi, and enjoy flexible perks.</h1>
          <button>DOWNLOAD APP</button>
        </div>
      </Grid>

      <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
        <div className={styles.footerSubTitle}>
          <h2>Sign Up</h2>
          <h3>Get notifications about blog posts, events, and new features.</h3>
          <input
            value={email}
            className={styles.footerInput}
            placeholder="Email"
            onChange={(e) => setEmail(e.target.value)}
          />
          {loading ? (
            <div className="loader"></div>
          ) : (
            <></>
            // <Button
            //   onClick={submit}
            //   className={styles.subscribeBtn}
            //   variant="contained"
            //   sx={
            //     {
            //       // maxWidth: 235,
            //       // marginLeft: "auto",
            //       // marginTop: 4,
            //       // height: 62,
            //     }
            //   }
            // >
            //   SUBSCRIBE
            // </Button>
          )}
        </div>
      </Grid>

      <Grid item xl={2} lg={2} md={2} sm={4} xs={12} container justifyContent="flex-start">
        <div className={styles.footerLinks}>
          <h4>Company</h4>
          <h5>
            <a href="#">About us</a>
          </h5>
          <h5>
            <a href="#">Privacy Policy</a>
          </h5>
          <h5>
            <a href="#">About us</a>
          </h5>
          <h5>
            <a href="#">Terms of Use</a>
          </h5>
        </div>
      </Grid>
      <Grid
        item
        xl={2}
        lg={2}
        md={2}
        sm={4}
        xs={12}
        container
        justifyContent={mobile ? "flex-start" : "center"}
      >
        <div className={styles.footerLinks}>
          <h4>Product</h4>
          <h5>
            <a href="#">For Companies</a>
          </h5>
          <h5>
            <a href="#">For Developers</a>
          </h5>
        </div>
      </Grid>
      <Grid
        item
        xl={2}
        lg={2}
        md={2}
        sm={4}
        xs={12}
        container
        justifyContent={mobile ? "flex-start" : "flex-end"}
      >
        <div className={styles.footerLinks}>
          <h4>Resources</h4>
          <h5>
            <a href="#">Help Center</a>
          </h5>
          <h5>
            <a href="#">Blog</a>
          </h5>
          <h5>
            <a href="#">Customer stories</a>
          </h5>
        </div>
      </Grid>

      <Grid
        item
        xl={6}
        lg={6}
        md={6}
        sm={12}
        xs={12}
        sx={{
          display: "flex",
          flexDirection: "column",
          marginTop: mobile ? "50px" : "100px",
        }}
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="91" height="16" viewBox="0 0 91 16" fill="none">
          <path
            d="M7.38215 4.80055C5.95888 4.80055 4.80037 5.95914 4.80037 7.3825C4.80037 8.80586 5.95888 9.96445 7.38215 9.96445C8.80542 9.96445 9.96394 8.80586 9.96394 7.3825C9.96394 5.95914 8.80636 4.80055 7.38215 4.80055ZM7.38215 4.80055C5.95888 4.80055 4.80037 5.95914 4.80037 7.3825C4.80037 8.80586 5.95888 9.96445 7.38215 9.96445C8.80542 9.96445 9.96394 8.80586 9.96394 7.3825C9.96394 5.95914 8.80636 4.80055 7.38215 4.80055ZM14.3774 0.307017C14.1812 0.10985 13.9145 0 13.6357 0L1.0488 0C0.770903 0 0.504274 0.10985 0.30712 0.307017C0.110904 0.503246 0.00012207 0.769891 0.00012207 1.04874V13.6365C0.00012207 13.9144 0.110904 14.182 0.30712 14.3782C0.504274 14.5753 0.770903 14.6852 1.0488 14.6852H13.6357C13.9145 14.6852 14.1812 14.5753 14.3774 14.3782C14.5745 14.182 14.6853 13.9144 14.6853 13.6365V1.04874C14.6853 0.769891 14.5745 0.503246 14.3774 0.307017ZM7.38215 12.03C4.82008 12.03 2.73494 9.94567 2.73494 7.3825C2.73494 4.81933 4.82008 2.73499 7.38215 2.73499C9.94422 2.73499 12.0294 4.82027 12.0294 7.3825C12.0294 9.94474 9.94516 12.03 7.38215 12.03ZM12.4049 3.67388C11.7308 3.67388 11.1844 3.12745 11.1844 2.45332C11.1844 1.7792 11.7308 1.23276 12.4049 1.23276C13.079 1.23276 13.6254 1.7792 13.6254 2.45332C13.6254 3.12745 13.079 3.67388 12.4049 3.67388ZM7.38215 4.80055C5.95888 4.80055 4.80037 5.95914 4.80037 7.3825C4.80037 8.80586 5.95888 9.96445 7.38215 9.96445C8.80542 9.96445 9.96394 8.80586 9.96394 7.3825C9.96394 5.95914 8.80636 4.80055 7.38215 4.80055Z"
            fill="white"
          />
          <path
            d="M86.1186 4.50345C85.4791 4.50125 84.8454 4.62512 84.2538 4.86795C83.6623 5.11078 83.1244 5.46782 82.671 5.91862C82.2177 6.36943 81.8577 6.90517 81.6117 7.49517C81.3657 8.08518 81.2385 8.71787 81.2374 9.35706V14.2607C81.2374 14.4598 81.3165 14.6507 81.4574 14.7914C81.5982 14.9322 81.7892 15.0113 81.9884 15.0113H83.7406C83.9398 15.0113 84.1308 14.9322 84.2716 14.7914C84.4124 14.6507 84.4915 14.4598 84.4915 14.2607V9.35706C84.4914 9.12972 84.5391 8.9049 84.6317 8.69726C84.7243 8.48962 84.8597 8.30381 85.0289 8.15194C85.1982 8.00008 85.3976 7.88558 85.6141 7.8159C85.8306 7.74621 86.0594 7.72292 86.2855 7.74753C86.691 7.79858 87.0637 7.9966 87.3328 8.30403C87.602 8.61147 87.7489 9.00694 87.7457 9.41543V14.2607C87.7457 14.4598 87.8248 14.6507 87.9656 14.7914C88.1064 14.9322 88.2974 15.0113 88.4966 15.0113H90.2488C90.448 15.0113 90.639 14.9322 90.7798 14.7914C90.9206 14.6507 90.9998 14.4598 90.9998 14.2607V9.35706C90.9987 8.71787 90.8715 8.08518 90.6255 7.49517C90.3795 6.90517 90.0195 6.36943 89.5661 5.91862C89.1128 5.46782 88.5749 5.11078 87.9833 4.86795C87.3918 4.62512 86.7581 4.50125 86.1186 4.50345Z"
            fill="white"
          />
          <path
            d="M78.9841 5.25391H76.7312C76.3165 5.25391 75.9803 5.58994 75.9803 6.00446V14.2606C75.9803 14.6751 76.3165 15.0112 76.7312 15.0112H78.9841C79.3988 15.0112 79.735 14.6751 79.735 14.2606V6.00446C79.735 5.58994 79.3988 5.25391 78.9841 5.25391Z"
            fill="white"
          />
          <path
            d="M77.8578 3.75279C78.8946 3.75279 79.7352 2.9127 79.7352 1.8764C79.7352 0.840091 78.8946 0 77.8578 0C76.8209 0 75.9804 0.840091 75.9804 1.8764C75.9804 2.9127 76.8209 3.75279 77.8578 3.75279Z"
            fill="white"
          />
          <path
            d="M63.6254 0.433241C63.6254 0.319681 63.5762 0.210773 63.4886 0.130474C63.4009 0.050175 63.2821 0.00506347 63.1582 0.00506347H60.8219C59.6455 -0.0486378 58.4936 0.326532 57.6181 1.0486C56.7426 1.77067 56.2145 2.78093 56.1493 3.85866V6.17082H53.8131C53.6891 6.17082 53.5703 6.21593 53.4827 6.29623C53.395 6.37653 53.3458 6.48544 53.3458 6.599V8.82552C53.3458 8.93908 53.395 9.04799 53.4827 9.12829C53.5703 9.20859 53.6891 9.2537 53.8131 9.2537H56.1493V14.9913C56.1493 15.1048 56.1986 15.2137 56.2862 15.294C56.3738 15.3743 56.4927 15.4195 56.6166 15.4195H59.4201C59.544 15.4195 59.6629 15.3743 59.7505 15.294C59.8382 15.2137 59.8874 15.1048 59.8874 14.9913V9.2537H62.3358C62.4397 9.25507 62.5412 9.22465 62.6241 9.16727C62.707 9.10988 62.7667 9.0288 62.7937 8.93685L63.4666 6.71032C63.4852 6.64706 63.4877 6.58073 63.4739 6.51644C63.4601 6.45215 63.4304 6.39161 63.387 6.3395C63.3437 6.28738 63.2879 6.24507 63.2239 6.21583C63.1599 6.18658 63.0895 6.17119 63.018 6.17082H59.8874V3.85866C59.9106 3.64669 60.0193 3.45028 60.1921 3.30776C60.3649 3.16525 60.5894 3.08687 60.8219 3.08794H63.1582C63.2821 3.08794 63.4009 3.04283 63.4886 2.96253C63.5762 2.88223 63.6254 2.77332 63.6254 2.65976V0.433241Z"
            fill="white"
          />
          <path
            d="M30.972 13.1581C32.1173 13.1921 33.2587 13.0218 34.3286 12.6572C35.3986 12.2927 36.3755 11.7412 37.2015 11.0354C38.0276 10.3297 38.686 9.48392 39.1379 8.54818C39.5899 7.61245 39.8261 6.60571 39.8327 5.58747C40.3576 5.01032 40.7473 4.34577 40.979 3.63288C40.9963 3.57655 40.9949 3.51698 40.975 3.46135C40.9551 3.40571 40.9175 3.35638 40.8668 3.31929C40.816 3.2822 40.7543 3.25894 40.689 3.2523C40.6237 3.24566 40.5577 3.25593 40.4988 3.28187C40.2242 3.39932 39.9151 3.43726 39.615 3.39034C39.3148 3.34343 39.0388 3.21402 38.8258 3.02034C38.5539 2.75577 38.225 2.54238 37.8586 2.39283C37.4922 2.24327 37.0957 2.16059 36.6927 2.14968C36.2897 2.13877 35.8883 2.19985 35.5123 2.32931C35.1363 2.45877 34.7934 2.65397 34.5039 2.90334C34.1075 3.24447 33.8171 3.67093 33.661 4.14126C33.505 4.61159 33.4885 5.1098 33.6132 5.58747C31.0185 5.72512 29.237 4.63082 27.8119 3.13046C27.7691 3.08738 27.713 3.05631 27.6507 3.04104C27.5883 3.02577 27.5223 3.02697 27.4607 3.04449C27.3991 3.062 27.3446 3.09508 27.3038 3.13968C27.263 3.18428 27.2377 3.23847 27.231 3.29564C26.9595 4.63386 27.1553 6.01386 27.7922 7.2503C28.4291 8.48673 29.4765 9.52038 30.7938 10.2124C29.9106 11.1125 28.6702 11.6747 27.3317 11.7816C27.2599 11.7922 27.1937 11.8224 27.1423 11.868C27.0909 11.9137 27.0568 11.9725 27.0449 12.0362C27.0329 12.0999 27.0437 12.1654 27.0757 12.2234C27.1076 12.2814 27.1592 12.3291 27.2232 12.3597C28.3878 12.8768 29.6702 13.1499 30.972 13.1581Z"
            fill="white"
          />
        </svg>
      </Grid>
      <Grid
        item
        xl={6}
        lg={6}
        md={6}
        sm={12}
        xs={12}
        container
        // direction="column"
        alignItems="flex-end"
        // alignContent="center"
        justifyContent="flex-end"
      >
        <h6 className={styles.footerCopyright}>Copyright 2024 – All rights reserved.</h6>
      </Grid>

      <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{width: "100%"}}>
          Successfully subscribed
        </Alert>
      </Snackbar>
      <Snackbar open={error} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error" sx={{width: "100%"}}>
          Already Subscribed
        </Alert>
      </Snackbar>
    </Grid>
  );
};

export default Footer;
