import React from "react";
import styles from "../../styles/Home.module.css";
import {Grid} from "@mui/material";

import useFetch from "../../customHooks/useFetch";

const Services = () => {
  const {data, error, loading} = useFetch(
    `${process.env.REACT_APP_API_BASE_URL}/website/data`,
    {
      page_code: "home",
      section_code: "section_three",
    },
    true
  );
  const service1 = {
    backgroundImage: `url(${process.env.REACT_APP_API_BASE_URL_ATTACHMENTS}/contentImages/${data?.images?.image_1})`,

    backgroundRepeat: "no-repeat, repeat",
    backgroundPosition: "center",
    backgroundSize: "auto",
    width: "338px",
    height: "242px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    borderRadius: "10px",
    textAlign: "left",
    marginBottom: 20,
  };
  const service2 = {
    backgroundImage: `url(${process.env.REACT_APP_API_BASE_URL_ATTACHMENTS}/contentImages/${data?.images?.image_2})`,

    backgroundRepeat: "no-repeat, repeat",
    backgroundPosition: "center",
    backgroundSize: "auto",
    width: "338px",
    height: "242px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    borderRadius: "10px",
    textAlign: "left",
    marginBottom: 20,
  };
  const service3 = {
    backgroundImage: `url(${process.env.REACT_APP_API_BASE_URL_ATTACHMENTS}/contentImages/${data?.images?.image_3})`,

    backgroundRepeat: "no-repeat, repeat",
    backgroundPosition: "center",
    backgroundSize: "auto",
    width: "338px",
    height: "242px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    borderRadius: "10px",
    textAlign: "left",
    marginBottom: 20,
  };

  const footer = {
    width: "338px",
    height: "115px",
    borderRadius: "0px 0px 10px 10px",
    background: "#018754",
    margin: 0,
    color: "#FFF",
    fontFamily: "Montserrat",
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "normal",
    paddingLeft: 20,
    paddingTop: 20,
  };
  return (
    <div className={styles.homeServicesSection} id="about-us">
      <h1>{data?.content?.title}</h1>
      <h2>{data?.content?.subtitle}</h2>

      <Grid container>
        <Grid
          item
          xl={4}
          lg={4}
          md={4}
          sm={12}
          xs={12}
          sx={{}}
          spacing={0}
          container
          direction="column"
          alignItems="center"
          alignContent="center"
          justifyContent="center"
        >
          <div style={service1}>
            <h3 style={footer}>{data?.content?.card_title1}</h3>
          </div>
        </Grid>

        <Grid
          item
          xl={4}
          lg={4}
          md={4}
          sm={12}
          xs={12}
          sx={{}}
          spacing={0}
          container
          direction="column"
          alignItems="center"
          alignContent="center"
          justifyContent="center"
        >
          <div style={service2}>
            <h3 style={footer}>{data?.content?.card_title2}</h3>
          </div>
        </Grid>

        <Grid
          item
          xl={4}
          lg={4}
          md={4}
          sm={12}
          xs={12}
          sx={{}}
          spacing={0}
          container
          direction="column"
          alignItems="center"
          alignContent="center"
          justifyContent="center"
        >
          <div style={service3}>
            <h3 style={footer}>{data?.content?.card_title3}</h3>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default Services;
