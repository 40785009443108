import React from "react";
import Hero from "./Hero";

import useFetch from "../../customHooks/useFetch";
import Contact from "./Contact";
import Partners from "./Partners";
import AboutUs from "./AboutUs";
import Services from "./Services";
import Recruit from "./Recruit ";
import Customise from "./Customise";
import Trusted from "./Trusted";

const Home = () => {
  // const { data, error, loading } = useFetch(
  //   `${process.env.REACT_APP_API_BASE_URL}/data/pages`,
  //   {}
  // );

  return (
    <>
      <Hero />
      <Partners />
      <AboutUs />
      <Services />
      <Recruit />
      <Customise />
      <Trusted />
    </>
  );
};

export default Home;
