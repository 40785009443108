import React from "react";
import styles from "../../styles/Home.module.css";
import useFetch from "../../customHooks/useFetch";
const Trusted = () => {
  const {data, error, loading} = useFetch(
    `${process.env.REACT_APP_API_BASE_URL}/website/data`,
    {
      page_code: "home",
      section_code: "section_six",
    },
    true
  );

  return (
    <div className={styles.trusted}>
      <h1>{data?.content?.title}</h1>
      <h2>{data?.content?.subtitle}</h2>
    </div>
  );
};

export default Trusted;
