import React from "react";
import styles from "../../styles/Home.module.css";
import {Grid} from "@mui/material";

import customiseImg1 from "../../assets/images/customise1.png";
import customiseImg2 from "../../assets/images/customise2.png";
import customiseImg3 from "../../assets/images/customise3.png";
import useFetch from "../../customHooks/useFetch";

const Customise = () => {
  const {data, error, loading} = useFetch(
    `${process.env.REACT_APP_API_BASE_URL}/website/data`,
    {
      page_code: "home",
      section_code: "section_five",
    },
    true
  );
  return (
    <Grid container spacing={2} className={styles.homeCustomiseSection}>
      <Grid
        item
        xl={12}
        lg={12}
        md={12}
        sm={12}
        xs={12}
        sx={{}}
        // spacing={2}
        // container
        // direction="column"
        // // alignItems="center"
        // // alignContent="center"
        // // justifyContent="center"
        className={styles.homeCustomiseTop}
      >
        <div
          style={{
            backgroundImage: `url(${process.env.REACT_APP_API_BASE_URL_ATTACHMENTS}/contentImages/${data?.images?.image_1})`,
            backgroundRepeat: "no-repeat, repeat",
            backgroundPosition: "bottom right",
            backgroundSize: "contain",
          }}
        >
          <h1>{data?.content?.card_title1}</h1>
          <h2>{data?.content?.card_subtitle1}</h2>
        </div>
      </Grid>

      <Grid
        item
        xl={6}
        lg={6}
        md={6}
        sm={12}
        xs={12}
        sx={{}}
        // spacing={2}
        // container
        // direction="column"
        // alignItems="center"
        // alignContent="center"
        // justifyContent="center"
        className={styles.homeCustomiseBottom}
      >
        <div
          style={{
            backgroundImage: `url(${process.env.REACT_APP_API_BASE_URL_ATTACHMENTS}/contentImages/${data?.images?.image_2})`,
            backgroundRepeat: "no-repeat, repeat",
            backgroundPosition: "bottom",
            backgroundSize: "auto",
          }}
        >
          <h1>{data?.content?.card_title2}</h1>
          <h2>{data?.content?.card_subtitle2}</h2>
        </div>
      </Grid>
      <Grid
        item
        xl={6}
        lg={6}
        md={6}
        sm={12}
        xs={12}
        sx={{}}
        // spacing={2}
        // container
        // direction="column"
        // alignItems="center"
        // alignContent="center"
        // justifyContent="center"
        className={styles.homeCustomiseBottom}
      >
        <div
          style={{
            backgroundImage: `url(${process.env.REACT_APP_API_BASE_URL_ATTACHMENTS}/contentImages/${data?.images?.image_3})`,
            backgroundRepeat: "no-repeat, repeat",
            backgroundPosition: "bottom",
            backgroundSize: "auto",
          }}
        >
          <h1>{data?.content?.card_title3}</h1>
          <h2>{data?.content?.card_subtitle3}</h2>
        </div>
      </Grid>
    </Grid>
  );
};

export default Customise;
