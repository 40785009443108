import React from "react";
import styles from "../../styles/Home.module.css";
import {Grid} from "@mui/material";
import useFetch from "../../customHooks/useFetch";

const AboutUs = () => {
  const {data, error, loading} = useFetch(
    `${process.env.REACT_APP_API_BASE_URL}/website/data`,
    {
      page_code: "home",
      section_code: "section_two",
    },
    true
  );

  return (
    <div className={styles.homeAboutUsSection} id="about-us">
      <h1>{data?.content?.title}</h1>
      <h2>{data?.content?.subtitle}</h2>

      <Grid container>
        <Grid
          item
          xl={6}
          lg={6}
          md={6}
          sm={12}
          xs={12}
          sx={{}}
          spacing={0}
          container
          direction="column"
          alignItems="center"
          alignContent="center"
          justifyContent="center"
        >
          <div className={styles.homeAboutUsContent}>
            <h3>{data?.content?.card_title1}</h3>
            <h4>{data?.content?.card_subtitle1}</h4>
          </div>
        </Grid>

        <Grid
          item
          xl={6}
          lg={6}
          md={6}
          sm={12}
          xs={12}
          sx={{}}
          spacing={0}
          container
          direction="column"
          alignItems="center"
          alignContent="center"
          justifyContent="center"
        >
          <div className={styles.homeAboutUsContent}>
            <h3>{data?.content?.card_title2}</h3>
            <h4>{data?.content?.card_subtitle2}</h4>
          </div>
        </Grid>
      </Grid>

      <Grid container>
        <Grid
          item
          xl={6}
          lg={6}
          md={6}
          sm={12}
          xs={12}
          sx={{}}
          spacing={0}
          container
          direction="column"
          alignItems="center"
          alignContent="center"
          justifyContent="center"
        >
          <div className={styles.homeAboutUsContent}>
            <h3>{data?.content?.card_title3}</h3>
            <h4>{data?.content?.card_subtitle3}</h4>
          </div>
        </Grid>

        <Grid
          item
          xl={6}
          lg={6}
          md={6}
          sm={12}
          xs={12}
          sx={{}}
          spacing={0}
          container
          direction="column"
          alignItems="center"
          alignContent="center"
          justifyContent="center"
        >
          <div className={styles.homeAboutUsContent}>
            <h3>{data?.content?.card_title4}</h3>
            <h4>{data?.content?.card_subtitle4}</h4>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default AboutUs;
