import React from "react";
import { Scrollbar, A11y, Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/bundle";
import img1 from "../../assets/images/img1.png";
const Slider = ({ data }) => {
  console.log(data);
  return (
    // <div className="bd-brand__area grey-bg pt-95 pb-65">
    <div className="bd-brand__area pt-95 pb-65">
      <div
      // className="container"
      >
        <div className="row  justify-content-between">
          <div className="col-12">
            <div className="bd-brand-active swiper-container">
              <div className="swiper-wrappers">
                <Swiper
                  modules={[Scrollbar, A11y, Autoplay]}
                  spaceBetween={30}
                  slidesPerView={1}
                  loop={true}
                  autoplay={{
                    delay: 2500,
                    disableOnInteraction: true,
                  }}
                  breakpoints={{
                    500: {
                      slidesPerView: 1,
                    },
                    768: {
                      slidesPerView: 2,
                    },
                    992: {
                      slidesPerView: 2,
                    },
                    1200: {
                      slidesPerView: 3,
                    },
                    1400: {
                      slidesPerView: 4,
                    },
                  }}
                >
                  {data &&
                    data.length > 0 &&
                    data?.map((image, index) => (
                      <SwiperSlide key={index}>
                        <div className="swiper-slides">
                          <div
                            className="bd-singel__brand mb-30"
                            style={{ textAlign: "center" }}
                          >
                            <a href="#">
                              <img src={image.src} alt="brand-thumb" />
                            </a>
                          </div>
                        </div>
                      </SwiperSlide>
                    ))}
                </Swiper>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Slider;
