let selectedLanguage = localStorage.getItem("lang") || "en";

const currentLanguage = (state = selectedLanguage, action) => {
  switch (action.type) {
    case "SET_LANGUAGE_FR":
      return (state = "fr");
    case "SET_LANGUAGE_EN":
      return (state = "en");
    default:
      return state;
  }
};

export default currentLanguage;
