import {Grid} from "@mui/material";
import React from "react";
import styles from "../../styles/Why.module.css";
import img0 from "../../assets/images/customise2.png";

import img1 from "../../assets/images/why1.png";
import img2 from "../../assets/images/why2.png";
import img3 from "../../assets/images/why3.png";
import img4 from "../../assets/images/why4.png";
import Benefits from "./benefits";
import useFetch from "../../customHooks/useFetch";
const WhyPage = () => {
  const {data, error, loading} = useFetch(
    `${process.env.REACT_APP_API_BASE_URL}/website/data`,
    {
      page_code: "why_kikundi",
      section_code: "page",
    },
    true
  );

  return (
    <>
      <div className={styles.whyPage}>
        <Grid container spacing={2}>
          <Grid
            className={styles.gridItem}
            // item
            xl={6}
            lg={6}
            md={6}
            sm={12}
            xs={12}
            sx={{}}
            order={{xs: 2, sm: 2, md: 1, lg: 1, xl: 1}}
            // spacing={2}
            container
            direction="column"
            alignItems="center"
            alignContent="center"
            justifyContent="center"
          >
            <div className={styles.content}>
              <h1>{data?.content?.card_title1}</h1>
              <h2>{data?.content?.card_subtitle1}</h2>
            </div>
          </Grid>
          <Grid
            className={styles.gridItem}
            // item
            xl={6}
            lg={6}
            md={6}
            sm={12}
            xs={12}
            sx={{}}
            order={{xs: 1, sm: 1, md: 2, lg: 2, xl: 2}}
            // spacing={2}
            container
            direction="column"
            alignItems="center"
            alignContent="center"
            // justifyContent="center"
          >
            <div className={styles.media}>
              <img
                src={`${process.env.REACT_APP_API_BASE_URL_ATTACHMENTS}/contentImages/${data?.images?.image_1}`}
                width={"100%"}
                height={"100%"}
              />
            </div>
          </Grid>

          <Grid
            className={styles.gridItem}
            // item
            xl={6}
            lg={6}
            md={6}
            sm={12}
            xs={12}
            sx={{}}
            order={{xs: 3, sm: 3, md: 3, lg: 3, xl: 3}}
            // spacing={2}
            container
            direction="column"
            alignItems="center"
            alignContent="center"
            justifyContent="center"
          >
            <div className={styles.media}>
              <img
                src={`${process.env.REACT_APP_API_BASE_URL_ATTACHMENTS}/contentImages/${data?.images?.image_2}`}
                width={"100%"}
                height={"100%"}
              />
            </div>
          </Grid>
          <Grid
            className={styles.gridItem}
            // item
            xl={6}
            lg={6}
            md={6}
            sm={12}
            xs={12}
            sx={{}}
            order={{xs: 4, sm: 4, md: 4, lg: 4, xl: 4}}
            // spacing={2}
            container
            direction="column"
            alignItems="center"
            alignContent="center"
            // justifyContent="center"
          >
            <div className={styles.content}>
              <h1>{data?.content?.card_title2}</h1>
              <h2>{data?.content?.card_subtitle2}</h2>
            </div>
          </Grid>

          <Grid
            className={styles.gridItem}
            // item
            xl={6}
            lg={6}
            md={6}
            sm={12}
            xs={12}
            sx={{}}
            order={{xs: 6, sm: 6, md: 5, lg: 5, xl: 5}}
            // spacing={2}
            container
            direction="column"
            alignItems="center"
            alignContent="center"
            justifyContent="center"
          >
            <div className={styles.content}>
              <h1>{data?.content?.card_title3}</h1>
              <h2>{data?.content?.card_subtitle3}</h2>
            </div>
          </Grid>
          <Grid
            className={styles.gridItem}
            // item
            xl={6}
            lg={6}
            md={6}
            sm={12}
            xs={12}
            sx={{}}
            order={{xs: 5, sm: 5, md: 6, lg: 6, xl: 6}}
            // spacing={2}
            container
            direction="column"
            alignItems="center"
            alignContent="center"
            // justifyContent="center"
          >
            <div className={styles.media}>
              <img
                src={`${process.env.REACT_APP_API_BASE_URL_ATTACHMENTS}/contentImages/${data?.images?.image_3}`}
                width={"100%"}
                height={"100%"}
              />
            </div>
          </Grid>

          <Grid
            className={styles.gridItem}
            // item
            xl={6}
            lg={6}
            md={6}
            sm={12}
            xs={12}
            sx={{}}
            order={{xs: 7, sm: 7, md: 7, lg: 7, xl: 7}}
            // spacing={2}
            container
            direction="column"
            alignItems="center"
            alignContent="center"
            justifyContent="center"
          >
            <div className={styles.media}>
              <img
                src={`${process.env.REACT_APP_API_BASE_URL_ATTACHMENTS}/contentImages/${data?.images?.image_4}`}
                width={"100%"}
                height={"100%"}
              />
            </div>
          </Grid>
          <Grid
            className={styles.gridItem}
            // item
            xl={6}
            lg={6}
            md={6}
            sm={12}
            xs={12}
            sx={{}}
            order={{xs: 8, sm: 8, md: 8, lg: 8, xl: 8}}
            // spacing={2}
            container
            direction="column"
            alignItems="center"
            alignContent="center"
            // justifyContent="center"
          >
            <div className={styles.content}>
              <h1>{data?.content?.card_title4}</h1>
              <h2>{data?.content?.card_subtitle4}</h2>
            </div>
          </Grid>

          <Grid
            className={styles.gridItem}
            // item
            xl={6}
            lg={6}
            md={6}
            sm={12}
            xs={12}
            sx={{}}
            order={{xs: 10, sm: 10, md: 9, lg: 9, xl: 9}}
            // spacing={2}
            container
            direction="column"
            alignItems="center"
            alignContent="center"
            justifyContent="center"
          >
            <div className={styles.content}>
              <h1>{data?.content?.card_title5}</h1>
              <h2>{data?.content?.card_subtitle5}</h2>
            </div>
          </Grid>
          <Grid
            className={styles.gridItem}
            // item
            xl={6}
            lg={6}
            md={6}
            sm={12}
            xs={12}
            sx={{}}
            order={{xs: 9, sm: 9, md: 10, lg: 10, xl: 10}}
            // spacing={2}
            container
            direction="column"
            alignItems="center"
            alignContent="center"
            // justifyContent="center"
          >
            <div className={styles.media}>
              <img
                src={`${process.env.REACT_APP_API_BASE_URL_ATTACHMENTS}/contentImages/${data?.images?.image_5}`}
                width={"100%"}
                height={"100%"}
              />
            </div>
          </Grid>
        </Grid>
      </div>
      <Benefits data={data} />
    </>
  );
};

export default WhyPage;
