import React from "react";
import styles from "../../styles/Company.module.css";
import partnersImgs from "../../assets/images/businesses.png";
import employeeImg from "../../assets/images/employee.png";
import {Grid} from "@mui/material";
import useFetch from "../../customHooks/useFetch";
const Company = () => {
  const {data, error, loading} = useFetch(
    `${process.env.REACT_APP_API_BASE_URL}/website/data`,
    {
      page_code: "company_kikundi",
      section_code: "page",
    },
    true
  );
  const {
    data: team,
    error: teamError,
    loading: teamLoading,
  } = useFetch(`${process.env.REACT_APP_API_BASE_URL}/website/team`, {});
  return (
    <div>
      <div className={styles.empowering}>
        <h1>{data?.content?.title1}</h1>
        <h2>{data?.content?.subtitle1}</h2>
      </div>
      <div className={styles.partners}>
        <h1>{data?.content?.title2}</h1>
        <h2>{data?.content?.subtitle2}</h2>

        <img src={partnersImgs} width={"100%"} />
      </div>

      <div className={styles.team}>
        <h1>{data?.content?.title3}</h1>
        <h2>{data?.content?.subtitle3}</h2>
        <Grid container>
          {team?.map((member, index) => (
            <Grid item xl={3} lg={3} md={6} sm={6} xs={12} sx={{}} key={index}>
              <div className={styles.employeeCard}>
                <img src={`${process.env.REACT_APP_API_BASE_URL_ATTACHMENTS}/usersImages/${member.image}`} />
                <h1>{member?.name}</h1>
                <h2>{member?.position_name}</h2>
              </div>
            </Grid>
          ))}
        </Grid>
      </div>
    </div>
  );
};

export default Company;
