import React from "react";
import styles from "../../styles/Customers.module.css";
import businessesImgs from "../../assets/images/businesses.png";
import {Grid} from "@mui/material";
import useFetch from "../../customHooks/useFetch";
const Customers = () => {
  const {data, error, loading} = useFetch(
    `${process.env.REACT_APP_API_BASE_URL}/website/data`,
    {
      page_code: "customers_kikundi",
      section_code: "page",
    },
    true
  );

  return (
    <div>
      <div className={styles.businesses}>
        <h1>{data?.content?.title}</h1>

        <h2>{data?.content?.subtitle}</h2>

        <img src={businessesImgs} width={"100%"} />
      </div>

      <div className={styles.data}>
        <h1>{data?.content?.cards_title}</h1>

        <Grid container>
          <Grid
            container
            direction="column"
            alignItems="center"
            alignContent="center"
            justifyContent="center"
            xl={3}
            lg={3}
            md={6}
            sm={6}
            xs={12}
            sx={{}}
          >
            <h2>{data?.content?.card_title1}</h2>
            <h3>{data?.content?.card_subtitle1}</h3>
          </Grid>
          <Grid
            container
            direction="column"
            alignItems="center"
            alignContent="center"
            justifyContent="center"
            xl={3}
            lg={3}
            md={6}
            sm={6}
            xs={12}
            sx={{}}
          >
            <h2>{data?.content?.card_title2}</h2>
            <h3>{data?.content?.card_subtitle2}</h3>
          </Grid>
          <Grid
            container
            direction="column"
            alignItems="center"
            alignContent="center"
            justifyContent="center"
            xl={3}
            lg={3}
            md={6}
            sm={6}
            xs={12}
            sx={{}}
          >
            <h2>{data?.content?.card_title3}</h2>
            <h3>{data?.content?.card_subtitle3}</h3>
          </Grid>
          <Grid
            container
            direction="column"
            alignItems="center"
            alignContent="center"
            justifyContent="center"
            xl={3}
            lg={3}
            md={6}
            sm={6}
            xs={12}
            sx={{}}
          >
            <h2>{data?.content?.card_title4}</h2>
            <h3>{data?.content?.card_subtitle4}</h3>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default Customers;
