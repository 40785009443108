import * as React from "react";
import { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import logo from "../../assets/logo/main.svg";
import styles from "../../styles/Layout.module.css";

const Header = () => {
  const drawerWidth = 240;

  const navItems = [
    { id: 0, name: "Why Kikundi", route: "/why" },
    { id: 1, name: "Benefits", route: "/benefits" },
    { id: 2, name: "Customers", route: "/customers" },
    { id: 3, name: "Company", route: "/company" },
  ];

  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Typography variant="h6" sx={{ my: 2 }} component={Link} to={"/"}>
        <img
          src={logo}
          width="125"
          style={{ marginTop: 10, marginBottom: 10 }}
        />
      </Typography>
      <Divider />
      <List>
        {navItems.map((item, index) => (
          <ListItem key={index} disablePadding>
            <ListItemButton
              className={styles.link}
              sx={{ textAlign: "center" }}
              component={Link}
              to={item.route}
            >
              <ListItemText primary={item.name} />
            </ListItemButton>
            {/* )} */}
          </ListItem>
        ))}
      </List>
      <Button sx={{ padding: 0 }} style={{ width: "100%", padding: 20 }}>
        <a style={{ width: "100%" }} className={styles.contactBtn} href="/">
          DOWNLOAD APP
        </a>
      </Button>
    </Box>
  );

  const [show, setShow] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [top, setTop] = useState(true);
  const controlNavbar = () => {
    if (typeof window !== "undefined") {
      if (window.scrollY < 200) {
        setTop(true);
      } else {
        setTop(false);
      }
      if (window.scrollY > lastScrollY) {
        // if scroll down hide the navbar
        setShow(false);
      } else {
        // if scroll up show the navbar
        setShow(true);
      }

      // remember current page location to use in the next move
      setLastScrollY(window.scrollY);
    }
  };
  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", controlNavbar);

      // cleanup function
      return () => {
        window.removeEventListener("scroll", controlNavbar);
      };
    }
  }, [lastScrollY]);

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar
        component="nav"
        style={{
          boxShadow: "none",
          marginTop: !show && "-130px",
          transition: "all 1s",
          backgroundColor: top ? "transparent" : "white",
          boxShadow: top ? "none" : "0px 5px 30px 0px  rgba(0, 0, 0, 0.5)",
        }}
      >
        <Toolbar
          style={{ display: "flex" }}
          sx={{ justifyContent: { xs: "", sm: "space-around" } }}
        >
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{
              mr: 2,
              display: { sm: "", md: "none" },
              color: "black",
            }}
          >
            <MenuIcon />
          </IconButton>
          <Button
            style={{ width: "125px" }}
            variant="h6"
            component={Link}
            to="/"
            sx={{
              width: 125,
              padding: 0,
              display: { xs: "none", sm: "block" },
            }}
          >
            <img src={logo} width="125" />
          </Button>
          <Box
            sx={{ display: { xs: "none", sm: "none", md: "flex" } }}
            style={{
              justifyContent: "space-around",
            }}
          >
            {navItems.map(
              (item, index) => (
                <Button className={styles.link} key={index}>
                  <a
                    style={{
                      color: "black",
                      textDecoration: "none",
                    }}
                    href={item.route}
                  >
                    {item.name}
                  </a>
                </Button>
              )
              // )
            )}
          </Box>
          <Button
            sx={{
              padding: 0,
              display: { xs: "none", sm: "block" },
              borderRadius: "max(calc(40px / 1.8), calc((5.8vw * 40) / 100))",
            }}
          >
            <a className={styles.contactBtn} href="/">
              DOWNLOAD APP
            </a>
          </Button>
        </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block", sm: "block", md: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
};

export default Header;
