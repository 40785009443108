import currentUser from "./currentUser";
import currentLanguage from "./currentLanguage";
import changeState from "./changeStateReducer";
import { combineReducers } from "redux";

const rootReducer = combineReducers({
  currentUser,
  changeState,
  currentLanguage,
});

export default rootReducer;
